<template>
  <div>
    <ViewTemplateReportPreview
      :panel="panel"
      :templateList="templateList"
      :templateReport="templateReport"
    />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportProductView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Operacional",
        title: "Saídas e Retornos",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/DeliveryDevolutionOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "registeredDate",
            title: "Dt Cadastro",
            type: "date",
            selected: false,
            requiredOData: false,
            styleHeader: "width:120px;",
            styleBody: "max-width: 120px; white-space: nowrap;",
          },
          {
            field: "number",
            title: "Número",
            type: "text",
            selected: true,
            requiredOData: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "width:70px;",
            styleBody: "max-width: 70px; white-space: nowrap;",
          },
          {
            field: "identification",
            title: "Identificação",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody:
              "max-width: 150px; white-space: nowrap;  font-style: italic;",
          },
          {
            field: "collaboratorName",
            title: "Consultor",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "companyName",
            title: "Razão Social/Nome",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "tradeName",
            title: "Fantasia/Apelido",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "contactName",
            title: "Contato",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:120px;",
            styleBody: "max-width: 120px; white-space: nowrap;",
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:120px;",
            styleBody: "max-width: 120px; white-space: nowrap;",
          },
          {
            field: "deliveryDevolution",
            title: "Período",
            type: "dateTime",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
            styleHeader: "width:130px;",
            styleBody: "max-width: 130px; white-space: nowrap;",
          },
          {
            field: "doneName",
            title: "Realizado",
            type: "text",
            selected: true,
            requiredOData: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "width:120px;",
            styleBody: "max-width: 120px; white-space: nowrap;",
          },
        ],
        rules: [
          {
            name: "deliveryDevolution",
            title: "Período",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
            },
            notDuplicate: true,
            isRequired: true,
          },
          {
            name: "identification",
            title: "Identificação",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "collaboratorId",
            title: "Consultor",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/human-resources/collaborator/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "customerSupplierId",
            title: "Cliente",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "type",
            title: "Tipo",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Saída", value: 1 },
              { title: "Retorno", value: 2 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "done",
            title: "Realizado",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Sim", value: true },
              { title: "Não", value: false },
            ],
            notDuplicate: true,
            isRequired: false,
          },
        ],
      },
    };
  },
};
</script>
